import BagelCornerLogo from 'core/assets/image/template/bagel-corner-logo.svg'
import BerlinerLogo from 'core/assets/image/template/berliner-logo.svg'
import CmdLogo from 'core/assets/image/template/cmd-logo.svg'
import CuveeNoireLogo from 'core/assets/image/template/cuvee-noire-logo.svg'
import FivePizzaLogo from 'core/assets/image/template/five-pizza-logo.svg'
import JourLogo from 'core/assets/image/template/jour-logo.svg'
import MamasCornerLogo from 'core/assets/image/template/mamas-corner-logo.svg'
import MifanLogo from 'core/assets/image/template/mifan-logo.svg'
import NewTokyoLogo from 'core/assets/image/template/new-tokyo-logo.svg'
import PhoodLogo from 'core/assets/image/template/phood-logo.svg'
import PokawaLogo from 'core/assets/image/template/pokawa-logo.svg'
import SaladandCoLogo from 'core/assets/image/template/saladandco-logo.svg'

import { Template } from '../interfaces/template.interface'

export const TEMPLATE_LOGO_MAP: Partial<Record<Template, any>> = {
  [Template.Pokawa]: PokawaLogo,
  [Template.FivePizza]: FivePizzaLogo,
  [Template.Jour]: JourLogo,
  [Template.Berliner]: BerlinerLogo,
  [Template.BagelCorner]: BagelCornerLogo,
  [Template.MamasCorner]: MamasCornerLogo,
  [Template.Phood]: PhoodLogo,
  [Template.CuveeNoire]: CuveeNoireLogo,
  [Template.Cmd]: CmdLogo,
  [Template.NewTokyo]: NewTokyoLogo,
  [Template.Mifan]: MifanLogo,
  [Template.SaladandCo]: SaladandCoLogo,
}
